import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Colors } from "../../styles/Colors";
import { PiWarningCircle } from "react-icons/pi";
const checkStockAlert = async () => {
  const token = localStorage.getItem("token");
  return await Axios.get(`${process.env.REACT_APP_API_URL}/v2/stock-alert`, {
    headers: { Authorization: "bearer " + token },
  });
};

export const StockAlert = () => {
  const [products, setProducts] = useState();

  const loadProducts = async () => {
    await checkStockAlert().then(({ data = [] }) => {
      setProducts(data.stockAlert.length + data.kits.length);
    });
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <>
      {products > 0 ? (
        <Link to="/app/stock" style={styles.stockAlertButton}>
          <PiWarningCircle
            size={20}
            color="#d84d38"
            style={{ marginBottom: "3px" }}
          />{" "}
          Estoque: {products}
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = {
  stockAlert: {
    display: "flex",
    padding: `10px 16px`,
    gap: 16,
    width: "100%",
    backgroundColor: Colors.red,
    alignItems: "center",
    justifyContent: "center",
  },
  stockAlertButton: {
    display: "flex",
    color: "#d84d38",
    borderRadius: 40,
    fontSize: 14,
    gap: "6px",
    textDecoration: "none",
    alignItems: "center",
    fontWeight: 500,
  },
};
