import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SaveIcon from "@material-ui/icons/Save";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import green from "@material-ui/core/colors/green";
import Loading from "../utils/Loading";

import AlertDialog from "../utils/AlertDialog";

class EditJob extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        title: "",
        description: "",
        active: false,
        email_credentials: false,
        admin: true,
        user: "5ebbd7d54ed3246583fb9103",
        company: "5eb706b5ae906327c265edd4",
        status: "5ec01035b6062a3a5700c7e5",
        type: "",
        executer: "",
        responsible: "",
        priority: 1,
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showErrorNewUser: false,
      showDeleteUserAlert: false,
      loading: true,
      showAlertNewUser: false,
      companies: [],
      taskTypes: [],
      executers: [],
      users: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
  }

  componentDidMount = async () => {
    const token = await localStorage.getItem("token");
    const _this = this;

    const { _id } = this.state.data;

    axios
      .get(`https://w3lib-manager-api.herokuapp.com/taskType`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          taskTypes: data,
        });

        _this.setState({
          loading: false,
        });
      });

    axios
      .get(`https://w3lib-manager-api.herokuapp.com/executer`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          executers: data,
        });

        _this.setState({
          loading: false,
        });
      });

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    axios
      .get(`https://w3lib-manager-api.herokuapp.com/task/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data,
        });

        _this.setState({
          loading: false,
        });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newUser();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .patch(`https://w3lib-manager-api.herokuapp.com/task/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newUser = async () => {
    let { data } = this.state;

    data._id = undefined;
    if (localStorage.getItem("user", "Cliente") == "Cliente") {
      data.user = localStorage.getItem("_id", "");
    }
    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(`https://w3lib-manager-api.herokuapp.com/task`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        // console.log('response', response);
        this.setState({
          saving: false,
          success: true,
          showAlertNewUser: true,
          data: response.data.data,
        });

        // this.props.history.goBack()

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        console.log("responserror", error.response.data.message);

        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onResetPasswordPress = () => {
    this.setState({ anchorEl: null, open: false, showAlert: true });
  };

  onDeleteUserPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });
  };

  render() {
    const {
      classes,
      saving,
      companies,
      users,
      executers,
      taskTypes,
      success,
      anchorEl,
      open,
      showAlert,
      showDeleteUserAlert,
      loading,
      showAlertNewUser,
      showErrorNewUser,
      error,
    } = this.state;
    const { data } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Paper className={classes.paper}>
        <Loading loading={loading} />

        <Typography component="h1" variant="h5">
          {data.name}
          <IconButton
            aria-label="More"
            aria-owns={true}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: 200,
              },
            }}
          >
            <MenuItem key={1} onClick={this.onDeleteUserPress}>
              Excluir Tarefa
            </MenuItem>
          </Menu>
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="title">Titulo</InputLabel>
            <Input
              id="title"
              name="title"
              autoFocus
              value={data.title}
              onChange={this.onChange}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <label
              htmlFor="company"
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Descrição
            </label>

            <textarea
              id="description"
              name="description"
              value={data.description}
              rows={5}
              style={{
                borderRadius: 5,
                borderColor: "rgba(0, 0, 0, 0.54)",
                marginTop: 5,
              }}
              onChange={this.onChange}
            />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <label
              htmlFor="type"
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Tipo de Chamado *
            </label>

            <select
              name="type"
              id="type"
              value={
                typeof data.type._id != "undefined" ? data.type._id : data.type
              }
              style={{ marginTop: 5, height: 30 }}
              onChange={this.onChange}
            >
              <option></option>
              {taskTypes.map((item, index) => {
                return <option value={`${item._id}`}>{item.name}</option>;
              })}
            </select>
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <label
              htmlFor="priority"
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Prioridade *
            </label>

            <select
              name="priority"
              id="priority"
              value={data.priority}
              style={{ marginTop: 5, height: 30 }}
              onChange={this.onChange}
            >
              <option></option>
              <option value={5}>Crítica</option>
              <option value={4}>Muito Alta</option>
              <option value={3}>Alta</option>
              <option value={2}>Média</option>
              <option value={1}>Baixa</option>
            </select>
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <label
              htmlFor="responsible"
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Responsável
            </label>

            <select
              name="responsible"
              id="responsible"
              value={
                typeof data.responsible._id != "undefined"
                  ? data.responsible._id
                  : data.responsible
              }
              style={{ marginTop: 5, height: 30 }}
              onChange={this.onChange}
            >
              <option></option>
              {executers
                .filter((user) => user.type == "Administrador")
                .map((item, index) => {
                  return <option value={`${item._id}`}>{item.name}</option>;
                })}
            </select>
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <label
              htmlFor="executer"
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Executor
            </label>

            <select
              name="executer"
              id="executer"
              value={
                typeof data.executer._id != "undefined"
                  ? data.executer._id
                  : data.executer
              }
              style={{ marginTop: 5, height: 30 }}
              onChange={this.onChange}
            >
              <option></option>
              {executers.map((item, index) => {
                return <option value={`${item._id}`}>{item.name}</option>;
              })}
            </select>
          </FormControl>

          <FormControl margin="normal" required>
            <InputLabel htmlFor="deadline">Prazo</InputLabel>
            <Input
              id="deadline"
              type="date"
              name="deadline"
              autoFocus
              value={data.deadline}
              onChange={this.onChange}
            />
          </FormControl>

          <div style={{ width: "100%", height: 1 }}></div>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.active}
                onChange={this.onCheckChange("active")}
                value="active"
                color="primary"
              />
            }
            label="Ativo"
          />

          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={saving}
              onClick={this.onSavePress}
            >
              <SaveIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Salvar
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={this.onCancelPress}
            >
              Cancelar
            </Button>
            {/* {this.state.error && <Typography>{this.state.error}</Typography>} */}
          </div>
        </form>

        {showAlert && (
          <AlertDialog
            msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
            title="Reenviar Senha"
            open={showAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });
                },
              },
              {
                label: "Reenviar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });

                  this.resetPassword();
                },
              },
            ]}
          />
        )}

        {showAlertNewUser && (
          <AlertDialog
            msg={`Nova tarefa criada.`}
            title="Tarefa"
            open={showAlertNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showAlertNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showErrorNewUser && (
          <AlertDialog
            msg={error}
            title="Falha ao Cadastrar Nova Tarefa"
            open={showErrorNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showErrorNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showDeleteUserAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir o usuário ${data.name}? Obs.: Esta ação é irreversível`}
            title="Excluir Usuário"
            open={showDeleteUserAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });

                  this.deleteUser();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </Paper>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditJob);
