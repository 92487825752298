import {
  Box,
  FormControl,
  InputBase,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Moment from "react-moment";
import { formatter, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer/index";
import ContentHeader from "../layout/components/contentHeader/index";
import { Text } from "../layout/components/text";
import Loading from "../utils/Loading";
import { handleDownloadXLS } from "../utils/downloadXLS";

Numeral.locale("pt-br");

class ListContracts extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      page: 0,
      rowsPerPage: 25,
      order: "asc",
      orderBy: "calories",
      status_filter: "all",
      status_contract: "all",
      data_inicio_filter: "",
      data_final_filter: "",
      loading: false,
      id_contract: "",
      cpf: "",
      contract_info: "",
      count: 0,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    await this.getContracts();
  };

  getContracts = async (pageGo = 0) => {
    const _this = this;
    const token = localStorage.getItem("token");
    const { rowsPerPage } = this.state;

    _this.setState({ loading: true });

    let url = new URL(window.location.href);

    if (url.searchParams.get("page")) {
      if (!url.searchParams.get("page")) {
        this.setState({ page: url.searchParams.get("page") });
      } else {
        let returnPage = url.searchParams.get("page");
        this.setState({ page: parseInt(returnPage) });
      }
    }

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/admin/contracts?limit=${rowsPerPage}&page=${pageGo}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then(async (response) => {
        const {
          data: { data = [], count },
        } = response;
        _this.setState({
          data,
          count,
          page: pageGo,
          loading: false,
        });
      });
  };

  clearFilters = () => {
    this.setState({
      status_filter: "all",
      status_contract: "all",
      data_inicio_filter: "",
      data_final_filter: "",
      loading: false,
      id_contract: "",
      cpf: "",
    });

    this.getContracts();
  };
  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };
  handleChange = (event) => {
    this.setState({ status_filter: event.target.value });
  };
  handleChangePage = (event, pageToGo) => {
    const { page: currentPage } = this.state;
    let nextPage = pageToGo;

    if (pageToGo > currentPage && pageToGo === 1) nextPage = 2;
    if (pageToGo < currentPage && pageToGo === 1) nextPage = 0;

    this.setState({ page: nextPage });
    this.getContracts(nextPage);
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value }, () =>
      this.getContracts()
    );
  };

  filtrarContracts = async () => {
    // this.setState({ loading: true })
    let {
      status_filter,
      data_inicio_filter,
      data_final_filter,
      status_contract,
      id_contract,
      cpf,
      searchTerm,
      page,
      rowsPerPage,
      order,
      orderBy,
    } = this.state;

    let data_filter = {};

    if (status_filter != "" && status_filter !== "all") {
      data_filter.status = status_filter;
    }
    if (id_contract != "") {
      data_filter.id_contract = id_contract;
    }

    if (data_inicio_filter != "") {
      data_filter.data_inicio = data_inicio_filter;
    }

    if (data_final_filter != "") {
      data_filter.data_final = data_final_filter;
    }
    if (cpf != "") {
      data_filter.cpf = cpf;
    }
    if (status_contract && status_contract != "all") {
      data_filter.status_contract = status_contract;
    }

    const token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/getListContractsFilter`,
        data_filter,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        const { data } = response;
        let data_contracts = data;
        if (searchTerm.length > 0) {
          const regex = /[0-9]/;
          if (regex.test(searchTerm)) {
            data_contracts = stableSort(
              data,
              getSorting(order, orderBy)
            ).filter((item) =>
              item.id_subscription.toString().includes(searchTerm.toString())
            );
          } else {
            data_contracts = stableSort(
              data,
              getSorting(order, orderBy)
            ).filter(
              (item) =>
                item.id_user != null &&
                item.id_user.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            );
          }
        } else {
          data_contracts = stableSort(data, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .filter((item) =>
              item.id_user?.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
        }
        if (data_contracts.length === 0) {
          alert("Contrato não encontrado.");
          return;
        }

        this.setState({
          data: data_contracts,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  getStatusContract = (status) => {
    if (status == "active") return "ATIVO";
    if (status == "pending") return "PENDENTE";
    if (status == "canceled") return "CANCELADO";
    if (status == "declined") return "REPROVADO";
    if (status == "finished") return "FINALIZADO";

    return status;
  };
  enterButton = (e) => {
    if (e.key === "Enter") {
      this.filtrarContracts();
    }
  };

  handleChangeCpf = ({ target: { name, value } }) => {
    if (name === "cpf") {
      let formattedCpf = value
        .replace(/\D/g, "")
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{2}){1,10}$/g, `$1.$2.$3-$4`);
      this.setState({ cpf: formattedCpf });
    }
  };

  downloadXLS = async (token, xlsFileName) => {
    this.setState({ loading: true });

    await handleDownloadXLS({
      token,
      xlsFileName,
      url: "/v2/downloadContract/xls",
    });
    this.setState({ loading: false });
  };

  render() {
    const {
      classes,
      data,
      count,
      searchTerm,
      page,
      rowsPerPage,
      order,
      orderBy,
      status_filter,
      data_inicio_filter,
      data_final_filter,
      loading,
      status_contract,
      id_contract,
      cpf,
    } = this.state;

    const columns = [
      {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "Status",
        align: "center",
      },
      {
        id: "id_subscription",
        numeric: true,
        disablePadding: false,
        label: "Pedido",
      },
      {
        id: "contract_info",
        numeric: true,
        disablePadding: false,
        label: "ID SAP",
      },
      { id: "name", numeric: false, disablePadding: false, label: "Cliente" },
      { id: "period", numeric: true, disablePadding: false, label: "Duração" },
      { id: "price", numeric: true, disablePadding: false, label: "Preço" },
      {
        id: "createdAt",
        numeric: true,
        disablePadding: false,
        label: "Data",
        align: "center",
      },
      {
        id: "status_contract",
        numeric: true,
        disablePadding: false,
        label: "Situação",
        align: "center",
      },
    ];

    const token = localStorage.getItem("token");
    const xlsFileName = `Contratos_Tuim_${new Date()
      .toISOString()
      .split("T")[0]
      .split("-")
      .reverse()
      .join("_")}`;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Contratos"
          buttons={[
            {
              text: "Exportar",
              tertiary: true,
              onClick: async () => await this.downloadXLS(token, xlsFileName),
            },
          ]}
        />
        {data.length > 0 && (
          <>
            <ContentContainer label="Filtros" fullWidth>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  marginTop: 10,
                }}
              >
                <Box style={{ display: "flex", gap: 8 }}>
                  <TextField
                    id="startDate"
                    label="Data inicial"
                    type="date"
                    variant="outlined"
                    value={data_inicio_filter}
                    onChange={(e) =>
                      this.setState({ data_inicio_filter: e.target.value })
                    }
                    InputLabelProps={styles.InputLabelProps}
                    InputProps={styles.InputProps}
                    inputProps={styles.inputProps}
                    style={{ borderRadius: 40 }}
                    fullWidth
                  />
                  <TextField
                    id="endDate"
                    label="Data final"
                    type="date"
                    variant="outlined"
                    value={data_final_filter}
                    onChange={(e) =>
                      this.setState({ data_final_filter: e.target.value })
                    }
                    InputLabelProps={styles.InputLabelProps}
                    InputProps={styles.InputProps}
                    inputProps={styles.inputProps}
                    style={{ borderRadius: 40 }}
                    fullWidth
                  />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="status_select_label">Status</InputLabel>
                    <Select
                      key="status_select_label"
                      value={status_filter}
                      onChange={(e) =>
                        this.setState({ status_filter: e.target.value })
                      }
                      InputLabelProps={styles.InputLabelProps}
                      InputProps={styles.InputProps}
                      inputProps={styles.inputProps}
                      style={{ borderRadius: 40, fontSize: 14 }}
                      label="Status"
                      labelId="status_select_label"
                      id="status_select"
                      fullWidth
                      defaultValue={"active"}
                    >
                      <MenuItem value={"all"} style={{ fontSize: 14 }}>
                        Todos
                      </MenuItem>
                      <MenuItem value={"active"} style={{ fontSize: 14 }}>
                        Ativo
                      </MenuItem>
                      <MenuItem value={"canceled"} style={{ fontSize: 14 }}>
                        Cancelado
                      </MenuItem>
                      <MenuItem value={"pending"} style={{ fontSize: 14 }}>
                        Pendente
                      </MenuItem>
                      <MenuItem value={"finished"} style={{ fontSize: 14 }}>
                        Finalizado
                      </MenuItem>
                      <MenuItem value={"declined"} style={{ fontSize: 14 }}>
                        Reprovado
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="status_contract_label">Situação</InputLabel>
                    <Select
                      id="status_contract"
                      labelId="status_contract_label"
                      value={status_contract || ""}
                      onChange={(e) =>
                        this.setState({ status_contract: e.target.value })
                      }
                      InputLabelProps={styles.InputLabelProps}
                      InputProps={styles.InputProps}
                      inputProps={styles.inputProps}
                      style={{ borderRadius: 40, fontSize: 14 }}
                      defaultValue={"all"}
                      fullWidth
                      label={`Situação`}
                    >
                      <MenuItem value={"all"} style={{ fontSize: 14 }}>
                        Todos
                      </MenuItem>
                      <MenuItem value={"pending"} style={{ fontSize: 14 }}>
                        Pendente
                      </MenuItem>
                      <MenuItem
                        value={"Preparando para a Entrega"}
                        style={{ fontSize: 14 }}
                      >
                        Preparando para a Entrega
                      </MenuItem>
                      <MenuItem value={"Entregue"} style={{ fontSize: 14 }}>
                        Entregue
                      </MenuItem>
                      <MenuItem value={"Reprovado"} style={{ fontSize: 14 }}>
                        Reprovado
                      </MenuItem>
                      <MenuItem value={"Juridico"} style={{ fontSize: 14 }}>
                        Jurídico
                      </MenuItem>
                      <MenuItem value={"Cancelado"} style={{ fontSize: 14 }}>
                        Cancelado
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="id-contract"
                    label="ID do contrato"
                    type="text"
                    variant="outlined"
                    value={id_contract}
                    onChange={(e) =>
                      this.setState({ id_contract: e.target.value })
                    }
                    InputLabelProps={styles.InputLabelProps}
                    InputProps={styles.InputProps}
                    inputProps={styles.inputProps}
                    style={{ borderRadius: 40, fontSize: 14 }}
                    fullWidth
                  />
                  <TextField
                    id="cpf"
                    type="text"
                    label="CPF"
                    variant="outlined"
                    value={cpf || ""}
                    name="cpf"
                    onChange={this.handleChangeCpf}
                    InputLabelProps={styles.InputLabelProps}
                    InputProps={styles.InputProps}
                    inputProps={{ ...styles.inputProps, maxLength: 14 }}
                    style={{ borderRadius: 40, fontSize: 14 }}
                    fullWidth
                  />
                </Box>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    border: `1px solid #c5c5c5`,
                    padding: `12px 10px`,
                    borderRadius: 40,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 4,
                    }}
                  >
                    <SearchIcon htmlColor="#ccc" fontSize="small" />
                  </div>
                  <InputBase
                    placeholder="José Alves, Maria de Castro..."
                    onChange={this.onSearchChange}
                    value={searchTerm}
                    style={{
                      marginLeft: 10,
                      width: "100%",
                      color: "#555",
                      fontSize: 14,
                    }}
                  />
                </div>
              </Box>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: 10,
                  justifyContent: "flex-end",
                }}
              >
                <TuimButton
                  secondary
                  text="Filtrar"
                  onClick={() => this.filtrarContracts()}
                />
                <TuimButton
                  text="Limpar Filtro"
                  tertiary
                  onClick={() => this.clearFilters()}
                />
              </div>
            </ContentContainer>
            <ContentContainer>
              <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Página anterior",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Próxima página",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columns.map(
                        (row) => (
                          <TableCell
                            key={row.id}
                            align={row.align}
                            padding={row.disablePadding ? "none" : "default"}
                            sortDirection={orderBy === row.id ? order : false}
                          >
                            <Tooltip
                              title="Sort"
                              placement={
                                row.numeric ? "bottom-end" : "bottom-start"
                              }
                              enterDelay={300}
                            >
                              <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={this.createSortHandler(row.id)}
                                style={{ color: "#444", fontSize: 14 }}
                              >
                                {row.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        ),
                        this
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => {
                      return (
                        <TableRow
                          key={row._id}
                          hover
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              `/app/contract/${row._id}?page=${page}`,
                              "_blank"
                            );
                            window.scroll({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <TableCell align="center">
                            <ContractStatusLabel status={row.status} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.id_subscription}
                          </TableCell>
                          <TableCell component="th">
                            {row?.contract_info?.docNum || "-"}
                          </TableCell>
                          <TableCell component="th">
                            {row?.id_user != null ? row?.id_user?.name : ""}
                          </TableCell>
                          <TableCell component="th">
                            {row.period} {pluralize("mês", row.period)}
                          </TableCell>
                          <TableCell component="th">
                            {formatter.currency(row?.payment?.price)}
                          </TableCell>
                          <TableCell align="center">
                            <Moment
                              format="DD/MM/YYYY HH:mm"
                              date={new Date(row.createdAt)}
                            ></Moment>
                          </TableCell>
                          <TableCell align="center">
                            {row.status_contract == "pendente-aprovacao-b2b"
                              ? "Pendente de Aprovação"
                              : row.status_contract}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Página anterior",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Próxima página",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Box>
            </ContentContainer>
          </>
        )}
      </>
    );
  }
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const contractStatus = {
  active: { status: "Ativo", color: Colors.lightGreen },
  pending: { status: "Pendente", color: Colors.yellow },
  canceled: { status: "Cancelado", color: Colors.red },
  declined: { status: "Reprovado", color: Colors.red },
  finished: { status: "Finalizado", color: "#bbb" },
};

const ContractStatusLabel = ({ status }) => {
  const { status: statusName, color } = contractStatus[status];
  return (
    <Box
      style={{
        backgroundColor: "#eee",
        padding: `2px 8px 2px 8px`,
        borderRadius: 4,
        borderLeft: `8px solid ${color}`,
      }}
    >
      <Text
        strong
        color="#555"
        style={{ textTransform: "uppercase", fontSize: 12 }}
      >
        {statusName}
      </Text>
    </Box>
  );
};

ListContracts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  InputProps: {
    style: {
      borderRadius: 40,
      paddingLeft: 10,
    },
  },
  inputProps: {
    style: {
      fontSize: 14,
      borderRadius: 40,
      paddingLeft: 10,
      WebkitBoxShadow: `0 0 0 1000px #fff inset`,
    },
  },
  InputLabelProps: {
    shrink: true,
    style: {
      marginRight: 30,
      marginLeft: 4,
    },
  },
};

export default withStyles(styles)(ListContracts);
