import React, { Component } from "react";
import { Text } from "../layout/components/text";

class Forbidden extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      loading: true,
    };
  }

  render() {
    return (
      <div
        style={{
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text strong>Você não tem permissão para acessar essa página.</Text>
      </div>
    );
  }
}

export default Forbidden;
