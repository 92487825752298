import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";

class ListWarns extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      loading: true,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this;

    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/admin/warnings`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data,
        });

        this.setState({ loading: false });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  render() {
    const { classes, data, searchTerm, loading } = this.state;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader title="Solicitações de Avisos" />

        <ContentContainer>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Nome do usuário ou do produto"
                onChange={this.onSearchChange}
                value={searchTerm}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Toolbar>
          {data.length > 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell style={{ width: 220 }} align="center">
                    Data
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .filter((item) => {
                    if (item.name)
                      return item.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase());

                    return item;
                  })
                  .map((row) => (
                    <TableRow
                      key={row._id}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push(`/app/warn/${row._id}`)
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.name || "Não identificado"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.product?.name || "Não identificado"}
                      </TableCell>
                      <TableCell align="center">{row.createdAt}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <p>Nenhum registro encontrado.</p>
          )}
        </ContentContainer>
      </>
    );
  }
}

ListWarns.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ececec",
    "&:hover": {
      backgroundColor: "#ececec",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 280,
    },
  },
});

export default withStyles(styles)(ListWarns);
