import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import { formatter } from "../../../functions";
import { ContentContainer } from "../../layout/components/contentContainer";
import ContentHeader from "../../layout/components/contentHeader";
import { Text } from "../../layout/components/text";
import Loading from "../../utils/Loading";
import { useExerciseOfRights } from "./index-hook";

export const ListExerciseOfRight = () => {
  const { data, dataLength, isLoading } = useExerciseOfRights();

  const columns = ["Nome", "Direito requisitado", "Enviada em"];
  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      <Loading loading={isLoading} />
      <ContentHeader title="Exercício de Direito" />
      {dataLength === 0 ? (
        <p>Nenhuma solicitação encontrada</p>
      ) : (
        <ContentContainer label={`Solicitações (${dataLength})`}>
          <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>
                      <TableSortLabel style={{ color: "#444", fontSize: 14 }}>
                        {column}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((request) => {
                  return (
                    <TableRow
                      key={request._id}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `/app/clients/exercise-of-right/${request._id}`,
                          "_blank"
                        );
                        window.scroll({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <TableCell>
                        <Text>{request.name}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{request.requestedRight}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>
                          {formatter.date(request.createdAt, "short", false)}
                        </Text>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </ContentContainer>
      )}
    </>
  );
};
