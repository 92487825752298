import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { Component } from "react";
import Dropzone from "../ui/components/upload/Dropzone";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";
import EditPressCategory from "./EditPressCategory";

class EditPress extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        title: "",
        order: 0,
        description: "",
        active: false,
        url: "",
        category: "",
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      list_products: [],
      selected: [],
      showAlert: false,
      showErrorNewUser: false,
      showDeleteDoorAlert: false,
      loading: true,
      showAlertNewDoor: false,
      permissions: [{ permission: "Administrador" }, { permission: "SEO" }],
      openModalProducts: "",
      lockers: [],
      categories: [],
      modalCategoryVisible: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteDoorPress = this.onDeleteDoorPress.bind(this);
    this.deleteDoor = this.deleteDoor.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;
    const _this = this;
    const token = await localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/press/categories`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        _this.setState({
          categories: data,
        });
      });

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/getPress/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        _this.setState({
          data,
          loading: false,
        });
      });
  };

  onDrop = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload, true);
  };

  processUpload = async (uploadedFile, mainImg = true) => {
    console.log("MAIN IMGGGG", mainImg);
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = await localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          data: {
            ...this.state.data,
            urlImg: data.url,
          },
        });
      })
      .catch((error) => console.log("error", error));
  };

  onChange = (e) => {
    let { data } = this.state;

    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };
  onChangeLocker = (e) => {
    let { data } = this.state;

    data.id_locker._id = e.target.value;

    this.setState({ data: data });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    let { data } = this.state;

    if (!data._id) {
      this.newPress();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/press/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newPress = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/press/add`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        // console.log('response', response);
        this.setState({
          saving: false,
          success: true,
          data: response.data.door,
          showAlertNewDoor: true,
        });

        _this.props.history.push(`${response.data.door._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  deleteDoor = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/locker/door/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.push("/app/lockers/doors");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onResetPasswordPress = () => {
    this.setState({ anchorEl: null, open: false, showAlert: true });
  };

  onDeleteDoorPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteDoorAlert: true });
  };
  showModal = () => {
    this.setState({ modalCategoryVisible: true });
  };

  render() {
    const {
      classes,
      saving,
      success,
      anchorEl,
      open,
      showDeleteDoorAlert,
      loading,
      showAlertNewDoor,
      showErrorNewUser,
      error,
      modalCategoryVisible,
      categories,
    } = this.state;
    const { data } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    const columnsModalProducts = [
      { id: "checkbox" },
      { id: "name", numeric: false, disablePadding: true, label: "Nome" },
      {
        id: "price",
        numeric: true,
        disablePadding: false,
        label: "Preço (R$)",
      },
      {
        id: "createdAt",
        numeric: true,
        disablePadding: false,
        label: "Data de Cadastro",
      },
    ];

    return (
      <Paper className={classes.paper}>
        <Loading loading={loading} />

        <Typography component="h1" variant="h5">
          {data.title || "Cadastro de Nova na Mídia"}
          <IconButton
            aria-label="More"
            aria-owns={true}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: 200,
              },
            }}
          >
            <MenuItem key={1} onClick={this.onDeleteDoorPress}>
              Excluir Na Mídia
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            style={{
              backgroundColor: "#105ae6",
              float: "right",
              color: "#fff",
            }}
            onClick={() => this.setState({ modalCategoryVisible: true })}
          >
            Nova Categoria
          </Button>
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="title">Titulo</InputLabel>
            <Input
              id="title"
              name="title"
              autoFocus
              value={data.title}
              onChange={this.onChange}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <TextField
              id="description"
              name="description"
              label="Descrição"
              multiline
              rows={4}
              defaultValue={data.description}
              variant="outlined"
              onChange={this.onChange}
            />
          </FormControl>

          <FormControl
            margin="normal"
            required
            style={{ maxWidth: 200, width: "100%" }}
          >
            <InputLabel htmlFor="category">Categoria</InputLabel>
            <Select
              labelId="category"
              id="category"
              name="category"
              value={data.category}
              onChange={this.onChange}
            >
              {categories.map((item, index) => {
                return <MenuItem value={item._id}>{item.title}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="url">URL</InputLabel>
            <Input
              id="url"
              name="url"
              autoFocus
              value={data.url}
              onChange={this.onChange}
            />
          </FormControl>
          <br />
          <br />

          <div className="form-group">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginBottom: 10, flex: 1 }}>Imagem Principal</div>
              <i
                className="fa fa-times-circle"
                style={{ color: "gray", fontSize: 16, cursor: "pointer" }}
                onClick={() => this.onDelete(0)}
              ></i>
            </div>
            <Dropzone
              onDrop={this.onDrop}
              txt="Arraste aqui a imagem da na Mídia ou clique para selecionar arquivos."
            />
            {data.urlImg ? (
              <img
                src={data.urlImg}
                style={{ maxWidth: 400, height: 300 }}
                alt="this-is-a"
              />
            ) : (
              ""
            )}
          </div>

          <br />

          <FormControl margin="normal" required>
            <InputLabel htmlFor="order">Ordem</InputLabel>
            <Input
              type="number"
              id="order"
              name="order"
              autoFocus
              value={data.order}
              onChange={this.onChange}
            />
          </FormControl>

          <div style={{ width: "100%", height: 1 }}></div>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.active}
                onChange={this.onCheckChange("active")}
                value="active"
                color="primary"
              />
            }
            label="Ativo"
          />

          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={saving}
              onClick={this.onSavePress}
            >
              <SaveIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Salvar
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={this.onCancelPress}
            >
              Cancelar
            </Button>
            {/* {this.state.error && <Typography>{this.state.error}</Typography>} */}
          </div>
        </form>

        {showAlertNewDoor && (
          <AlertDialog
            msg={`Nova Porta cadastrada com sucesso.`}
            title="Nova Porta"
            open={showAlertNewDoor}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showAlertNewDoor: false,
                  });
                },
              },
            ]}
          />
        )}

        {showErrorNewUser && (
          <AlertDialog
            msg={error}
            title="Falha ao Cadastrar Nova Porta"
            open={showErrorNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showErrorNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showDeleteDoorAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir a porta ${data.codLoopBox}? Obs.: Esta ação é irreversível`}
            title="Excluir Porta"
            open={showDeleteDoorAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteDoorAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteDoorAlert: false,
                  });

                  this.deleteDoor();
                },
                color: "primary",
              },
            ]}
          />
        )}

        <EditPressCategory
          modalVisible={modalCategoryVisible}
          onCloseModal={() => this.setState({ modalCategoryVisible: false })}
        />
      </Paper>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditPress);
