import React from "react";
import { PiWarning } from "react-icons/pi";
import { useFetch } from "../../../hooks/useFetch";

export const HeaderWarn = () => {
  const { data } = useFetch({ url: "/v2/admin/warnings/count" });

  return (
    <a
      href="/app/warns"
      style={{
        display: "flex",
        alignItems: "center",
        gap: 6,
        textDecoration: "none",
      }}
    >
      <PiWarning size={20} color="#ffa632" />
      <span style={{ color: "#ffa632" }}>Avisos: {data.count}</span>
    </a>
  );
};
