import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCheck, FaWhatsapp } from "react-icons/fa";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { formatter } from "../../../functions";
import { Colors } from "../../../styles/Colors";
import { TuimButton } from "../../layout/Button";
import { ContentContainer } from "../../layout/components/contentContainer";
import { Text } from "../../layout/components/text";
import Loading from "../../utils/Loading";
import { useCep } from "../../../hooks/cep/useCep";
import { transformUfInName } from "../../utils/Cep";
import { masks } from "../../utils/Masks";

const FormErrorField = ({ field }) => {
  return (
    <>
      <Text key={`${field}`} small style={{ color: "#d84d38", marginTop: -10 }}>
        Preencha o campo <span style={{ fontWeight: 500 }}>"{field}"</span>{" "}
        corretamente.
      </Text>
    </>
  );
};

export const CreateContract = (data) => {
  const [handleModal, setHandleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractCreated, setContractCreated] = useState(false);
  const [copy, setCopy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [vindiUrl, setVindiUrl] = useState("");
  const [formFields, setFormFields] = useState({
    name: data.data?.id_user?.name ?? "",
    email: data.data?.id_user?.email ?? "",
    dateBirth: data.data?.id_user?.dateBirth
      ? formatter
          .date(data.data?.id_user?.dateBirth, "short", false)
          .split("/")
          .reverse()
          .join("-")
      : "",
    cpf: data.data?.id_user?.cpf ?? "",
    mobileNumber: data.data?.id_user?.mobileNumber ?? "",
    cep: data.data?.cep ?? "",
    rua: data.data?.id_user?.address?.rua ?? "",
    bairro: data.data?.id_user?.address?.bairro ?? "",
    numero: data.data?.id_user?.address?.numero ?? "",
    haveComplement: true,
    complemento: data.data?.id_user?.address?.complemento ?? false,
    cidade: data.data?.id_user?.address?.cidade ?? "",
    estado: data.data?.id_user?.address?.estado ?? "",
    uf: data.data?.id_user?.address?.uf ?? "",
    receiveUser: true,
    deliveryDate: data.data?.deliveryDate?.split("T")?.[0] ?? "",
    receiveDelivery: data.data?.id_user?.address?.receiveDelivery ?? false,
    receivePhoneDelivery:
      data.data?.id_user?.address?.receivePhoneDelivery ?? false,
  });

  useEffect(() => {
    if (formFields.cep) {
      const requestViaCepApi = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const response = await useCep(formFields.cep);
        if (!response) return;
        settingUpAddress(response);
      };
      requestViaCepApi();
    }
  }, [formFields.cep]);

  const settingUpAddress = ({ data }) => {
    setFormFields({
      ...formFields,
      bairro: data.bairro,
      cidade: data.localidade,
      uf: data.uf,
      rua: data.logradouro,
      estado: transformUfInName(data.uf),
      complemento: data.complemento ? data.complemento : false,
    });
  };

  const CopyComponent = {
    Icon: copy ? LuCopyCheck : LuCopy,
    Text: copy ? "Copiado" : "Copiar",
  };

  function handleChange({ target }) {
    let value = target.value;
    const field = target.name;

    value = masks(value, field);

    const newFormFields = {
      ...formFields,
      [field]: value,
    };

    setFormFields(newFormFields);
  }

  const handleChangeReceiveUser = ({ target }) => {
    setFormFields({
      ...formFields,
      receiveUser: !target.checked,
    });
  };

  const handleChangeHaveComplement = ({ target }) => {
    setFormFields({
      ...formFields,
      haveComplement: !target.checked,
    });
  };

  const handleOpen = () => {
    setHandleModal(!handleModal);
  };

  const handleCopy = () => {
    if (copy) return;

    navigator.clipboard.writeText(vindiUrl);
    setCopy(true);
  };

  const handleSendWhatsAppMsg = () => {
    const msg = `Este é o link para efetuar o pagamento do seu pedido Tuim: ${vindiUrl}`;
    const phoneNumber = formFields?.mobileNumber?.replace(/\D/g, "");
    const url = `http://api.whatsapp.com/send/?phone=${phoneNumber}&text=${msg}`;

    window.open(url);
  };

  const nonMandatoryFields = () => {
    const formErrors = [];

    if (!formFields.complemento && formFields.haveComplement) {
      setFormFields({
        ...formFields,
        complemento: false,
      });
      formErrors.push("complemento");
    }

    if (!formFields.receiveDelivery && !formFields.receiveUser) {
      setFormFields({
        ...formFields,
        receiveDelivery: false,
      });
      formErrors.push("Nome do Recebedor");
    }

    if (!formFields.receivePhoneDelivery && !formFields.receiveUser) {
      setFormFields({
        ...formFields,
        receivePhoneDelivery: false,
      });
      formErrors.push("Telefone do Recebedor");
    }
    return formErrors;
  };

  const handleCreateContract = async () => {
    setLoading(true);
    const nonMandatoryFieldsErrors = nonMandatoryFields();

    const formErrors = [...nonMandatoryFieldsErrors];

    Object.entries(formFields).forEach(([key, value]) => {
      if (value === null || value === undefined || value === "") {
        formErrors.push(key);
      }

      if (key === "cep" && value.length !== 9) {
        formErrors.push(key);
      }

      if (
        (formFields.receivePhoneDelivery &&
          key === "receivePhoneDelivery" &&
          value.length !== 15) ||
        (key === "mobileNumber" && value.length !== 19)
      ) {
        formErrors.push(key);
      }
    });

    if (formErrors.length > 0) {
      setLoading(false);
      setErrors(formErrors);
      return alert(
        `Atenção! Os seguintes campos não foram preenchidos corretamente: ${formErrors.map(
          (error) => `\n- ${error}`
        )}`
      );
    }

    const token = localStorage.getItem("token");

    const body = {
      cartData: data,
      formFields: formFields,
      fromContract: "tuim-admin",
    };

    const createContract = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/admin/createContract`,
          body,
          {
            headers: { Authorization: "bearer " + token },
          }
        )
        .then((response) => {
          setContractCreated(response?.data?.id);
          setVindiUrl(response?.data?.vindiUrl);

          return response;
        })
        .catch((err) => {
          console.log("Erro ao tentar criar assinatura - ", err);
          alert("Não foi possível criar o contrato!");
          handleOpen();
          return err;
        })
        .finally(() => setLoading(false));
    };
    createContract();
  };

  return (
    <>
      <TuimButton
        fullWidth
        text="Criar Contrato"
        secondary
        // eslint-disable-next-line no-undef
        onClick={() => handleOpen()}
      />

      {handleModal && (
        <>
          {loading ? (
            <Loading loading={loading} />
          ) : (
            <Box style={{ zIndex: 9999 }}>
              {contractCreated ? (
                <Box style={styles.root}>
                  <ContentContainer gap={32}>
                    <Box
                      style={{
                        display: "flex",
                        gap: 14,
                        alignItems: "center",
                        alignSelf: "center",
                        color: Colors.darkGreen,
                      }}
                    >
                      <Text title color="inherit">
                        Contrato criado com sucesso
                      </Text>
                      <FaCheck size={20} color={Colors.darkGreen} />
                    </Box>
                    <Text style={{ textAlign: "center" }} strong>
                      Link para pagamento:
                    </Text>
                    <Box
                      style={{
                        display: "flex",
                        gap: 14,
                        alignItems: "center",
                        alignSelf: "center",
                        color: Colors.darkGreen,
                      }}
                    >
                      <a
                        href={vindiUrl}
                        style={{ color: Colors.textRegular }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Box
                          style={{
                            display: "flex",
                            padding: "1rem",
                            background: "#f8f8f8",
                            borderRadius: "10px",
                            maxWidth: 420,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Text
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {vindiUrl}
                          </Text>
                        </Box>
                      </a>
                      <Box
                        style={{
                          display: "flex",
                          gap: 12,
                          alignItems: "flex-end",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                            cursor: copy ? "initial" : "pointer",
                          }}
                          onClick={handleCopy}
                        >
                          <CopyComponent.Icon
                            size={20}
                            color={Colors.darkGreen}
                          />
                          <Text detail>{CopyComponent.Text}</Text>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                            cursor: "pointer",
                          }}
                          onClick={handleSendWhatsAppMsg}
                        >
                          <FaWhatsapp size={22} color={Colors.darkGreen} />
                          <Text detail>WhatsApp</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 14,
                      }}
                    >
                      <TuimButton
                        secondary
                        text={"Ver contrato"}
                        onClick={() =>
                          window.open(`/app/contract/${contractCreated}`)
                        }
                      />
                      <TuimButton
                        tertiary
                        text={"Fechar"}
                        onClick={() => {
                          handleOpen();
                          window.location.reload();
                        }}
                      />
                    </Box>
                  </ContentContainer>
                </Box>
              ) : (
                <Box style={styles.contentWrapper}>
                  <ContentContainer
                    style={{ padding: 15, width: 400 }}
                    gap={24}
                  >
                    <FormGroup>
                      <Button
                        style={{
                          width: 40,
                          alignSelf: "flex-end",
                          color: "#444",
                        }}
                        onClick={handleOpen}
                      >
                        X
                      </Button>
                      <Box
                        style={{
                          maxHeight: 600,
                          overflowY: "auto",
                          padding: 25,
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 14,
                          }}
                        >
                          <Text strong color={Colors.darkGreen + "aa"}>
                            Dados Pessoais
                          </Text>
                          <TextField
                            type="text"
                            label={`Nome`}
                            value={formFields.name}
                            name="name"
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("name") && (
                            <FormErrorField field="name" />
                          )}

                          <TextField
                            type="text"
                            name="email"
                            label={`Email`}
                            value={formFields.email}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("email") && (
                            <FormErrorField field="email" />
                          )}

                          <TextField
                            label={`Data de Nascimento`}
                            name="dateBirth"
                            type="date"
                            value={formFields.dateBirth}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("dateBirth") && (
                            <FormErrorField field="dateBirth" />
                          )}

                          <TextField
                            label={`CPF`}
                            name="cpf"
                            value={formFields.cpf}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                            inputProps={{ maxLength: 14 }}
                          />
                          {errors.includes("cpf") && (
                            <FormErrorField field="cpf" />
                          )}

                          <TextField
                            label={`Celular`}
                            name="mobileNumber"
                            value={formFields.mobileNumber}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                            inputProps={{ maxLength: 19 }}
                          />
                          {errors.includes("mobileNumber") && (
                            <FormErrorField field="mobileNumber" />
                          )}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 14,
                            // minWidth: 360,
                            marginTop: 32,
                          }}
                        >
                          <Text strong color={Colors.darkGreen + "aa"}>
                            Dados de Entrega
                          </Text>
                          <TextField
                            label={`CEP`}
                            name="cep"
                            value={formFields.cep}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                            inputProps={{ maxLength: 9 }}
                          />
                          {errors.includes("cep") && (
                            <FormErrorField field="cpf" />
                          )}

                          <TextField
                            label={`Rua`}
                            name="rua"
                            value={formFields.rua}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("rua") && (
                            <FormErrorField field="rua" />
                          )}

                          <TextField
                            label={`Bairro`}
                            name="bairro"
                            value={formFields.bairro}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("bairro") && (
                            <FormErrorField field="bairro" />
                          )}

                          <TextField
                            label={`Numero`}
                            name="numero"
                            value={formFields.numero}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("numero") && (
                            <FormErrorField field="numero" />
                          )}

                          <FormGroup
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FormControlLabel
                              control={<Checkbox />}
                              label={<Text>Não há complemento</Text>}
                              name="known"
                              onChange={handleChangeHaveComplement}
                            />
                          </FormGroup>
                          {formFields.haveComplement && (
                            <TextField
                              label={`Complemento`}
                              name="complemento"
                              onChange={handleChange}
                              InputProps={styles.InputProps}
                              InputLabelProps={styles.InputLabelProps}
                            />
                          )}
                          {errors.includes("complemento") &&
                            formFields.haveComplement && (
                              <FormErrorField field="complemento" />
                            )}

                          <TextField
                            label={`Cidade`}
                            name="cidade"
                            value={formFields.cidade}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("cidade") && (
                            <FormErrorField field="cidade" />
                          )}

                          <TextField
                            label={`Estado`}
                            name="estado"
                            value={formFields.estado}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("estado") && (
                            <FormErrorField field="estado" />
                          )}

                          <TextField
                            label={`UF`}
                            name="uf"
                            value={formFields.uf}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                            inputProps={{ maxLength: 2 }}
                          />
                          {errors.includes("uf") && (
                            <FormErrorField field="UF" />
                          )}

                          <TextField
                            label={`Data de Entrega`}
                            name="deliveryDate"
                            type="date"
                            value={formFields.deliveryDate}
                            onChange={handleChange}
                            InputProps={styles.InputProps}
                            InputLabelProps={styles.InputLabelProps}
                          />
                          {errors.includes("deliveryDate") && (
                            <FormErrorField field="deliveryDate" />
                          )}

                          <FormGroup
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FormControlLabel
                              control={<Checkbox />}
                              label={<Text>Outra pessoa irá receber</Text>}
                              name="known"
                              onChange={handleChangeReceiveUser}
                            />
                          </FormGroup>
                          {!formFields.receiveUser && (
                            <>
                              <TextField
                                label={`Nome do Recebedor`}
                                name="receiveDelivery"
                                onChange={handleChange}
                                InputProps={styles.InputProps}
                                InputLabelProps={styles.InputLabelProps}
                              />
                              {errors.includes("Nome do Recebedor") && (
                                <FormErrorField field="Nome do Recebedor" />
                              )}

                              <TextField
                                label={`Telefone`}
                                name="receivePhoneDelivery"
                                onChange={handleChange}
                                InputProps={styles.InputProps}
                                InputLabelProps={styles.InputLabelProps}
                                inputProps={{ maxLength: 15 }}
                              />
                              {errors.includes("Telefone do Recebedor") && (
                                <FormErrorField field="Telefone do Recebedor" />
                              )}
                            </>
                          )}
                        </Box>
                        <TuimButton
                          fullWidth
                          text={"Gerar Contrato"}
                          onClick={handleCreateContract}
                          style={{ marginTop: 16 }}
                        />
                      </Box>
                    </FormGroup>
                  </ContentContainer>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

const styles = {
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000066",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99999,
  },
  contentWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000066",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99999,
  },
  InputProps: {
    style: {
      fontSize: 14,
    },
  },
  InputLabelProps: {
    shrink: true,
  },
};
