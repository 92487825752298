import axios from "axios";
import { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";

export const useShowExerciseOfRights = (id) => {
  const [showAnonymizeModal, setShowAnonymizeModal] = useState(false);

  const openAnonymizeModal = () => {
    setShowAnonymizeModal(true);
  };

  const closeAnonymizeModal = () => {
    setShowAnonymizeModal(false);
  };

  const data = useFetch({ url: `/v2/admin/exercise-of-rights/${id}` });

  const anonymize = async (userId) => {
    const token = localStorage.getItem("token");
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/admin/anonymize/${userId}?requestId=${id}`,
      {},
      {
        headers: { Authorization: "bearer " + token },
      }
    );

    return result;
  };

  return {
    ...data,
    anonymizeModalOpen: showAnonymizeModal,
    openAnonymizeModal,
    closeAnonymizeModal,
    anonymize,
  };
};
