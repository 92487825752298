export const routes = {
  Administrador: [
    "ListUsers",
    "EditUser",
    "ListCart",
    "CartMetrics",
    "Cart",
    "StockListAlert",
    "EditProfile",
    "ListCategories",
    "EditCategory",
    "ListCoupons",
    "EditCoupon",
    "ListProducts",
    "RelatorioProduct",
    "EditProduct",
    "ListBrokers",
    "EditBroker",
    "ListFaq",
    "EditFaq",
    "ListSeoTags",
    "EditSeo",
    "ListContracts",
    "Relatorio",
    "Contract",
    "tuimboxReports",
    "ListKits",
    "EditKit",
    "ListDiscount",
    "ListPrice",
    "ListAmbients",
    "EditAmbient",
    "ListStyles",
    "EditStyle",
    "ListCalendar",
    "EditCalendar",
    "ListLocation",
    "EditLocation",
    "ListBlockedClient",
    "EditBlockedClient",
    "BlackList",
    "ImportProducts",
    "ImportProducts",
    "ContractReport",
    "ListAdInvestment",
    "EditAdInvestment",
    "ContractTemplate",
    "ListArticles",
    "EditArticle",
    "ListFeatures",
    "EditFeature",
    "ListClients",
    "EditClient",
    "ListBudgets",
    "Budget",
    "NewBudget",
    "ListHs",
    "EditHs",
    "ListWarns",
    "EditWarn",
    "ListUnits",
    "EditUnit",
    "ListPartners",
    "ListBuildings",
    "EditPartner",
    "EditBuilding",
    "Dashboard",
    "ListLogs",
    "ListTasks",
    "EditTask",
    "ViewTask",
    "ListPress",
    "EditPress",
    "ListQuotations",
    "QuotationDetails",
    "ListTranslations",
    "EditTranslation",
    "ArticlesList",
  ],
  Financeiro: [
    "EditProfile",
    "tuimboxReports",
    "ListBlockedClient",
    "EditBlockedClient",
    "ListLocationsBlacklist",
  ],
  SEO: [
    "EditProfile",
    "ListPress",
    "EditPress",
    "ListArticles",
    "EditArticle",
    "ListFaq",
    "EditFaq",
    "ListSeoTags",
    "EditSeo",
    "ArticlesList",
  ],
  DPO: ["ListExerciseOfRight", "ShowExerciseOfRight"],
  Marketing: [
    "ArticlesList",
    "EditArticle",
    "ListPress",
    "EditPress",
    "ListFaq",
    "EditFaq",
  ],
};
