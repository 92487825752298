import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import React, { Component } from "react";
import Card from "../cads";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";
import AlertDialog from "../utils/AlertDialog";
import LogClient from "./logClient";

class EditUser extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      info: {
        card_number_last_four: "",
        bandeira: "",
      },
      payment_profiles: [],
      data: {
        _id,
        name: "",
        email: "",
        active: false,
        email_credentials: false,
        cart: [],
        address: [
          {
            rua: "",
            cep: "",
            cidade: "",
            telefone_adicional: "",
            complemento: "",
            referencia: "",
            uf: "",
            estado: "",
            periodo: "",
            numero: "",
          },
        ],
        cpf: "",
        rg: "",
        mobileNumber: "",
        dateBirth: "",
      },
      updatedFields: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showErrorNewUser: false,
      showDeleteUserAlert: false,
      page_selected: 0,
      loading: true,
      showAlertNewUser: false,
      permissions: [{ permission: "Administrador" }, { permission: "SEO" }],
      partners: [],
      tuimpoints: [],
      modalPoints: false,
      showAlertPointsDelete: false,
      tuimpoints_id: null,
      logsModal: false,
      logsIdSubscription: 0,
      blockedId: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.blockClient = this.blockClient.bind(this);
    this.checkIfClientIsBlocked = this.checkIfClientIsBlocked.bind(this);
    this.unblockClient = this.unblockClient.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        if (data.address == null) {
          data.address = [
            {
              rua: "",
              cep: "",
              cidade: "",
              telefone_adicional: "",
              complemento: "",
              referencia: "",
              uf: "",
              estado: "",
              periodo: "",
              numero: "",
            },
          ];
        }

        _this.setState({ data });
        this.getPaymentProfiles();
        this.checkIfClientIsBlocked();
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/partners/all`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          partners: data,
          loading: false,
        });
      })
      .finally(() => {
        _this.setState({
          loading: false,
        });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    let { name, value } = e.target;

    if (name === "mobileNumber") {
      value = value.replace(/\D/g, "");
      let phoneRegex = value.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
      if (phoneRegex[0].length >= 8) {
        value = `(${phoneRegex[1]}) ` + `${phoneRegex[2]}-${phoneRegex[3]}`;
      }
      if (phoneRegex[0].length < 8) {
        value = `(${phoneRegex[1]}) ` + phoneRegex[2];
      }
      if (phoneRegex[0].length <= 2) {
        value = `(${phoneRegex[1]}`;
      }
      if (phoneRegex[0].length == 0) {
        value = "";
      }
    }

    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      updatedFields: [...new Set([...this.state.updatedFields, name])],
      success: false,
    });
  };

  onChangeDate = (e) => {
    const { data } = this.state;
    const { name, value } = e.target;

    const timestamp = Date.parse(value);
    if (isNaN(timestamp)) return;

    let newDate = new Date(value).toISOString();

    this.setState({
      data: {
        ...data,
        [name]: newDate,
      },
      updatedFields: [...new Set([...this.state.updatedFields, name])],
      success: false,
    });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      updatedFields: [...new Set([...this.state.updatedFields, name])],
      success: false,
    });
  };

  onSavePress = async () => {
    const { data, updatedFields } = this.state;

    if (!data._id) {
      this.newUser();
      return;
    }

    this.setState({
      saving: true,
    });

    data.updatedFields = updatedFields;
    const token = await localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/user/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newUser = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/user`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          showAlertNewUser: true,
          data: response.data.data,
        });

        // this.props.history.goBack()

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        console.log("responserror", error.response.data.message);

        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  resetPassword = async () => {
    const { data } = this.state;

    const token = await localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/login/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
      })
      .catch((error) => {
        console.log("aqui em reset catch", error);
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteUser = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.push("/app/users");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  handleChange = (event, newValue) => {
    this.setState({ page_selected: newValue });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onResetPasswordPress = () => {
    this.setState({ anchorEl: null, open: false, showAlert: true });
  };

  onDeleteUserPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });
  };

  getPaymentProfiles = () => {
    const { data } = this.state;
    let id_vindi = data.idVindi;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/vindi/paymentprofile/` + id_vindi,
        {}
      )
      .then((response) => {
        const dados = response;

        this.setState({
          payment_profiles: dados.data.data.payment_profiles,
        });
      });
  };

  getTuimPoints = async () => {
    const { _id } = this.state.data;

    const token = await localStorage.getItem("token");
    const _this = this;
    axios
      .get(`${process.env.REACT_APP_API_URL}/app/tuimpoints/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then(async (response) => {
        const { data } = response;
        let tuimpoints_value = 0;

        await data.map((item, index) => {
          tuimpoints_value = tuimpoints_value + Number(item.value);
        });
        _this.setState({
          tuimpoints: data,
          tuimpoints_value,
        });
      });
  };

  removePoints = async () => {
    const { tuimpoints_id } = this.state;

    const token = await localStorage.getItem("token");
    const _this = this;
    if (!tuimpoints_id) {
      alert("Favor selecionar um TuimPoints");
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_API_URL}/tuimpoints/${tuimpoints_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then(async (response) => {
        alert("Tuim Points removido com sucesso!");
      });
  };

  checkIfClientIsBlocked = async () => {
    const token = localStorage.getItem("token");
    this.setState({ ...this.state, loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/blockClient`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        let checkIfUserIsBlocked = data.filter(
          (user) => user.id_user == this.state.data._id
        );
        this.setState({
          ...this.state,
          ...(checkIfUserIsBlocked.length > 0 && {
            blockedId: checkIfUserIsBlocked[0]._id,
          }),
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ ...this.state, loading: false });
        console.log(error);
      });
  };

  blockClient = async () => {
    const token = localStorage.getItem("token");
    const data = {
      name: this.state.data.name,
      id_user: this.state.data._id,
      cpf: this.state.data.cpf,
    };

    this.setState({ ...this.state, loading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/blockClient`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({ ...this.state, loading: false });
        this.props.history.go(0);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      });
  };

  unblockClient = async () => {
    const { blockedId: userId } = this.state;
    const token = localStorage.getItem("token");

    this.setState({ ...this.state, loading: true });

    axios
      .delete(`${process.env.REACT_APP_API_URL}/blockClient/${userId}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        this.props.history.go(0);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  render() {
    const {
      data,
      logsModal,
      blockedId,
      partners,
      showAlert,
      showDeleteUserAlert,
      loading,
      showAlertNewUser,
      showErrorNewUser,
      error,
      showAlertPointsDelete,
      payment_profiles,
    } = this.state;

    return (
      <>
        <ContentHeader
          title={data.name}
          subtitle={blockedId ? `Cliente bloqueado` : ``}
          buttons={[
            { text: "Salvar", onClick: this.onSavePress },
            ...(blockedId
              ? [
                  {
                    text: "Desbloquear",
                    destructive: true,
                    onClick: this.unblockClient,
                  },
                ]
              : [
                  {
                    text: "Bloquear",
                    destructive: true,
                    onClick: this.blockClient,
                  },
                ]),
          ]}
          dotsMenuItems={[
            { text: "Resetar Senha", action: this.onResetPasswordPress },
            { text: "Excluir Usuário", action: this.onDeleteUserPress },
          ]}
          blockedClient={true}
        />
        <Loading loading={loading} />

        {logsModal && (
          <LogClient
            modalVisible={logsModal}
            id={data._id}
            onCloseModal={() => this.setState({ logsModal: false })}
          />
        )}

        <ContentContainer label="Dados pessoais">
          <FormControl margin="normal" required fullWidth>
            <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <TextField
                id="name"
                label="Nome"
                name="name"
                value={data.name}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
              <TextField
                id="email"
                label="E-mail"
                name="email"
                value={data.email}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
              <TextField
                id="mobileNumber"
                label="Celular"
                name="mobileNumber"
                value={data.mobileNumber}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
              <TextField
                id="cpf"
                label="CPF"
                name="cpf"
                value={data.cpf}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
              <TextField
                id="rg"
                label="RG"
                name="rg"
                value={data.rg}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
              <TextField
                id="dateBirth"
                label="Data de Nascimento"
                name="dateBirth"
                type="date"
                value={data?.dateBirth?.split("/")?.reverse()?.join("-") || ""}
                onChange={this.onChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                fullWidth
              />
            </Box>
            <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
              <InputLabel id="id_partner_label">Parceria</InputLabel>
              <Select
                name="id_partner"
                key="id_partner_label"
                value={data.id_partner}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                label="Parceria"
                labelId="id_partner_label"
                id="id_partner"
                fullWidth
                defaultValue={""}
              >
                {partners.map((partner) => (
                  <MenuItem value={partner._id} style={{ fontSize: 14 }}>
                    {partner.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={data.active}
                onChange={this.onCheckChange("active")}
                value="active"
                color="primary"
              />
            }
            label="Ativo"
          />
          {!data._id && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.email_credentials}
                    onChange={this.onCheckChange("email_credentials")}
                    value="active"
                    color="primary"
                  />
                }
                label="Enviar credenciais de acesso por e-mail?"
              />
            </FormGroup>
          )}
        </ContentContainer>

        <ContentContainer label="Cartões de crédito">
          {payment_profiles.map((p, index) => {
            return (
              <Card
                data={p.created_at}
                status={p.status}
                numero={p.card_number_last_four}
                bandeira={p.payment_company.code}
                name={p.holder_name}
              ></Card>
            );
          })}
        </ContentContainer>

        {showAlert && (
          <AlertDialog
            msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
            title="Reenviar Senha"
            open={showAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });
                },
              },
              {
                label: "Reenviar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });

                  this.resetPassword();
                },
              },
            ]}
          />
        )}
        {showAlertPointsDelete && (
          <AlertDialog
            msg={`Deseja remover estes pontos ? `}
            title="Tuim Points"
            open={showAlertPointsDelete}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlertPointsDelete: false,
                  });
                },
              },
              {
                label: "Remover",
                onPress: () => {
                  this.setState({
                    showAlertPointsDelete: false,
                  });

                  this.removePoints();
                },
              },
            ]}
          />
        )}

        {showAlertNewUser && (
          <AlertDialog
            msg={`Novo usuário cadastrado com sucesso, um e-mail com a senha foi enviado para ${data.email}.`}
            title="Novo Usuário"
            open={showAlertNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showAlertNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showErrorNewUser && (
          <AlertDialog
            msg={error}
            title="Falha ao Cadastrar Novo Usuário"
            open={showErrorNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showErrorNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showDeleteUserAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir o usuário ${data.name}? Obs.: Esta ação é irreversível`}
            title="Excluir Usuário"
            open={showDeleteUserAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });

                  this.deleteUser();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  teste: {
    height: "auto",
    maxHeight: 386,
    overflow: "auto",
    Border: "none",
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditUser);
