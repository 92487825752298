import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { msg, title, buttons = [], open } = this.props;

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ borderRadius: 12 }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons.map(({ onPress, color = "default", label }, index) => (
            <Button key={index} onClick={onPress} color={color}>
              {label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
