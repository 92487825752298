import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#004146",
    },
    secondary: {
      main: "#79b751",
      light: "#0066ff",
      contrastText: "#FFF",
    },
    zx: {
      main: "#d6e1e1",
      secondary: "rgb(248,250,223)",
    },
  },
});
