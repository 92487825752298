import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import classNames from "classnames";
import React, { Component } from "react";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
class EditCoupon extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        title: "",
        description: "",
        code: "",
        discount: "",
        discount_price: "",
        limitedUse: "",
        limitedUserUse: "",
        active: false,
        type: "cart",
        minMonths: "",
        maxMonths: "",
        user: "",
        cycles: "",
        delivery_price: "",
        min_price: "",
        max_price: "",
        exemptFirst: false,
        msgCart: "",
        exchange: false,
      },
      users: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showDeleteCouponAlert: false,
      loading: true,
      page_selected: 0,
      cities: [],
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteCouponPress = this.onDeleteCouponPress.bind(this);
    this.deleteCoupon = this.deleteCoupon.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;
    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/serviceLocation`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        _this.setState({
          cities: data,
        });

        this.setState({ loading: false });
      });

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/coupon/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        _this.setState({
          data: data,
        });

        this.setState({ loading: false });
      });

    axios
      .get("https://api.tuim.com.br/partnershipByType/broker", {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          users: data,
        });

        _this.setState({
          loading: false,
        });
      });
  };

  onChange = (e) => {
    const { data } = this.state;

    let value = e.target.value;
    if (e.target.name === "msgCart") {
      value = value.slice(0, 63);
    }

    if (e.target.name === "code") {
      value = value.trim();
      console.log(value);
    }

    if (e.target.name === "discount" && value > 100) {
      return;
    }

    this.setState({
      data: {
        ...data,
        [e.target.name]: value,
      },
      success: false,
    });
  };

  onChangeSelect = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };
  onChangeSelectMultiple = (e) => {
    const { data } = this.state;
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        let location = options[i].value.split("-");
        let locationData = {
          city: location[0],
          state: location[1],
        };
        value.push(locationData);
      }
    }

    this.setState({
      data: {
        ...data,
        [e.target.name]: value,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    console.log(data);

    if (
      data.code === null ||
      data.code === "" ||
      data.maxMonths === null ||
      data.maxMonths === "" ||
      data.minMonths === null ||
      data.minMonths === "" ||
      data.msgCart === null ||
      data.msgCart === ""
    )
      return alert("Todos os campos obrigatórios* devem ser preenchidos");

    if (!data._id) {
      this.newCoupon();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");

    return axios
      .patch(`${process.env.REACT_APP_API_URL}/coupon/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newCoupon = async () => {
    const { data } = this.state;

    if (
      data.code === null ||
      data.code === "" ||
      data.maxMonths === null ||
      data.maxMonths === "" ||
      data.minMonths === null ||
      data.minMonths === "" ||
      data.msgCart === null ||
      data.msgCart === ""
    )
      return alert("Todos os campos obrigatórios* devem ser preenchidos");

    data._id = undefined;
    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    return axios
      .post(`${process.env.REACT_APP_API_URL}/coupon`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          data: response.data.data,
        });

        this.props.history.goBack();

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteCoupon = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/coupon/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.push("/app/coupon");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onDeleteCouponPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteCouponAlert: true });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ page_selected: newValue });
  };

  render() {
    const {
      classes,
      saving,
      success,
      anchorEl,
      open,
      showDeleteCouponAlert,
      loading,
      page_selected,
      cities,
    } = this.state;
    const { data, users } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Paper className={classes.paper}>
        <Loading loading={loading} />

        <Typography component="h1" variant="h5">
          {data.title}
          <IconButton
            aria-label="More"
            aria-owns={true}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: 200,
              },
            }}
          >
            <MenuItem key={0} onClick={this.onDeleteCouponPress}>
              Excluir Cupom
            </MenuItem>
          </Menu>

          <h3 style={{ fontSize: 17 }}>Cadastrar Cupom</h3>
        </Typography>

        <AppBar position="static">
          <Tabs
            value={page_selected}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Detalhes do Cupom" {...this.a11yProps(0)} />
            <Tab label="Parceiro" {...this.a11yProps(1)} />
            <Tab label="Outros Detalhes" {...this.a11yProps(2)} />
            <Tab label="Cidades Aceitas" {...this.a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={page_selected} index={0}>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Título</InputLabel>

              <Input
                id="title"
                name="title"
                required
                autofocus
                value={data.title}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="description">Descrição</InputLabel>

              <Input
                id="description"
                name="description"
                value={data.description}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="msgCart">Mensagem no Carrinho</InputLabel>

              <Input
                id="msgCart"
                name="msgCart"
                value={data.msgCart}
                maxLength="64"
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="code">Código Cupom</InputLabel>

              <Input
                id="code"
                name="code"
                value={data.code}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <Box
              style={{
                display: "flex",
                backgroundColor: "transparent",
                gap: 15,
                margin: `30px 0px`,
              }}
            >
              {/* Percentual */}
              <Box style={{ display: "flex", flex: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="discount">
                    Percentual de Desconto
                  </InputLabel>
                  <Input
                    id="discount"
                    name="discount"
                    value={data.discount}
                    onChange={this.onChange}
                    disabled={!!data?.discount_price}
                  />
                </FormControl>
              </Box>
              <Box style={{ display: "flex", flex: 1 }}>
                {/* Dinheiro */}
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="discount_price">
                    Desconto em Dinheiro
                  </InputLabel>
                  <Input
                    id="discount_price"
                    name="discount_price"
                    value={data.discount_price}
                    onChange={this.onChange}
                    disabled={!!data?.discount}
                  />
                </FormControl>
              </Box>
            </Box>

            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="cycles">Número de ciclos</InputLabel>

              <Input
                id="cycles"
                name="cycles"
                value={data.cycles}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="minMonths">
                Número mínimo de meses
              </InputLabel>

              <Input
                id="minMonths"
                name="minMonths"
                value={data.minMonths}
                onChange={this.onChange}
                required
              />
            </FormControl>
            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="maxMonths">
                Número máximo de meses
              </InputLabel>

              <Input
                id="maxMonths"
                name="maxMonths"
                value={data.maxMonths}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="min_price">
                Valor mínimo da mensalidade
              </InputLabel>

              <Input
                id="min_price"
                name="min_price"
                value={data.min_price || ""}
                onChange={this.onChange}
                required
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="max_price">
                Valor máximo da mensalidade
              </InputLabel>

              <Input
                id="max_price"
                name="max_price"
                value={data.max_price || ""}
                onChange={this.onChange}
                required
              />
            </FormControl>
            <br />
            <br />
            <InputLabel htmlFor="type">Tipo de Cupom</InputLabel>

            <br />
            <select
              name="type"
              id="type"
              value={data.type}
              onChange={this.onChangeSelect}
              required
            >
              <option value="cart">Carrinho</option>
              <option value="delivery">Taxa de Entrega</option>
              <option value="cart-delivery">Carrinho / Taxa de Entrega</option>
            </select>

            {data.type === "cart-delivery" ? (
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="delivery_price">
                  Valor de Desconto da Taxa de Entrega
                </InputLabel>

                <Input
                  id="delivery_price"
                  name="delivery_price"
                  value={data.delivery_price}
                  onChange={this.onChange}
                  required
                />
              </FormControl>
            ) : (
              ""
            )}
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="limitedUse">Limite de Utilização</InputLabel>

              <Input
                id="limitedUse"
                name="limitedUse"
                value={data.limitedUse}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="limitedUserUse">
                Limite de Utilização por Usuário
              </InputLabel>

              <Input
                id="limitedUserUse"
                name="limitedUserUse"
                value={data.limitedUserUse}
                onChange={this.onChange}
                required
              />
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={data.active}
                  onChange={this.onCheckChange("active")}
                  value="active"
                  color="primary"
                />
              }
              label="Ativo"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={data.exchange}
                  onChange={this.onCheckChange("exchange")}
                  value={data.exchange}
                  color="primary"
                />
              }
              label="Permuta"
            />
          </form>
        </TabPanel>
        <TabPanel value={page_selected} index={1}>
          <br />
          <br />
          <InputLabel htmlFor="user">Parceiro deste Cupom</InputLabel>
          <br />

          <select
            name="user"
            id="user"
            value={data.user}
            onChange={this.onChangeSelect}
          >
            <option></option>

            {typeof users != "undefined"
              ? users.map((item, index) => {
                  return (
                    <option key={`${item._id}___${index}`} value={item._id}>
                      {item.name}
                    </option>
                  );
                })
              : ""}
          </select>
          <br />
          <br />
        </TabPanel>
        <TabPanel value={page_selected} index={2}>
          <br />
          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={data.exemptFirst}
                onChange={this.onCheckChange("exemptFirst")}
                color="primary"
              />
            }
            label="Isentar a primeira mensalidade"
          />
        </TabPanel>

        <TabPanel value={page_selected} index={3}>
          <br />
          <br />
          <InputLabel htmlFor="user">Cidades Aceitas</InputLabel>
          <br />
          <div style={{ display: "flex" }}>
            <select
              name="cityAccept"
              id="cityAccept"
              multiple
              onChange={this.onChangeSelectMultiple}
              style={{ padding: "20px", height: 400, width: 400 }}
            >
              <option></option>

              {typeof cities != "undefined"
                ? cities.map((item, index) => {
                    return (
                      <option
                        key={`list_cities_${item.state}-${index}`}
                        value={item.state + "-" + item.city}
                      >
                        {item.state + " - " + item.city}
                      </option>
                    );
                  })
                : ""}
            </select>
            <div style={{ width: 300 }}>
              <ul>
                {typeof data.cityAccept != "undefined"
                  ? data.cityAccept.map((item, index) => {
                      return <li>{item.city + " - " + item.state}</li>;
                    })
                  : ""}
              </ul>
            </div>
          </div>
          <br />
          <br />
        </TabPanel>

        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={saving}
            onClick={this.onSavePress}
          >
            <SaveIcon
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            Salvar
            {saving && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={this.onCancelPress}
          >
            Cancelar
          </Button>
          {this.state.error && <Typography>{this.state.error}</Typography>}
        </div>

        {showDeleteCouponAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir o cupom ${data.title}? Obs.: Esta ação é irreversível`}
            title="Excluir Cupom"
            open={showDeleteCouponAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteCouponAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteCouponAlert: false,
                  });

                  this.deleteCoupon();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </Paper>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "450px",
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditCoupon);
