import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
  Checkbox,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SaveIcon from "@material-ui/icons/Save";
import green from "@material-ui/core/colors/green";
import classNames from "classnames";
import AlertDialog from "../utils/AlertDialog";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
class EditLocation extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        delivery_exemption: [],
        type: "city",
        km: 0,
        cep: "",
        endereco: "",
        state: "",
        city: "",
        showExemptionMessage: true,
      },
      typeRule: "",
      regra: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      handleClose: false,
      isConfirmOpen: false,
      showAlert: false,
      showDeleteUnitAlert: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteUnitPress = this.onDeleteUnitPress.bind(this);
    this.deleteUnit = this.deleteUnit.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  componentDidMount = () => {
    const { data } = this.state;

    if (!data._id || data._id == "edit") {
      this.setState({
        data: {
          ...this.state.data,
          _id: "",
        },
      });
    }

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/serviceLocation/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          data: response.data,
        });

        // this.props.history.goBack()
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onSavePress = async () => {
    const { data } = this.state;

    console.log("save data", data);

    if (!data._id) {
      this.newLocation();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/serviceLocation/${data._id}`,
        data,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log("", error);
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newLocation = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/serviceLocation`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { message, create } = response.data;

        if (create) {
          this.setState({
            saving: false,
            success: true,
            data: {
              _id: "",
              delivery_exemption: [],
              delivery_price: "",
              type: "city",
              km: 0,
              cep: "",
              endereco: "",
              state: "",
              city: "",
            },
          });
          this.props.history.goBack();
          alert(message);
        } else {
          alert(message);
          this.setState({
            saving: false,
          });
        }

        // _this.props.history.push(`${response.data.data._id}`)
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteUnit = async () => {};

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onDeleteUnitPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteUnitAlert: true });
  };

  onCheckChange = (e) => {
    let condicao = "";
    if (e.target.value == "true") {
      condicao = false;
    } else {
      condicao = true;
    }
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: condicao,
      },
    });
  };
  onChange = (e) => {
    let { name, value } = e.target;

    if (name == "cep") {
      value = value.replace(/^(\d{5})(\d)/, "$1-$2");
      if (value.length <= 9) {
        this.setState({
          data: {
            ...this.state.data,
            [name]: value,
          },
        });
      }
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
        },
      });
    }
  };
  onChangeArray = (e) => {
    this.setState({
      regra: {
        ...this.state.regra,
        [e.target.name]: e.target.value,
      },
    });
  };
  modalRegra = () => {
    this.setState({
      open: true,
    });
  };
  regraSalvar = (e) => {
    const { regra } = this.state;
    const { data } = this.state;
    const { delivery_exemption } = data;
    delivery_exemption.push(regra);
    this.setState({
      data: {
        ...this.state.data,
        delivery_exemption,
      },
    });

    this.setState({
      open: false,
      handleClose: false,
    });
  };

  deleteRegra = (index) => {
    const { delivery_exemption } = this.state.data;
    delivery_exemption.splice(index, 1);
    this.setState({
      data: {
        ...this.state.data,
        delivery_exemption,
      },
    });
  };
  onChangeCheck = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type: e.target.value,
      },
    });
  };
  onChangeCheckKm = (e) => {
    let val = Number(e.target.value);
    this.setState({
      data: {
        ...this.state.data,
        km: val,
      },
    });
  };
  changeRule = (e) => {
    let dados = this.state.data.delivery_exemption;
    let id = e.target.id;
    let val = e.target.value;
    let name = e.target.name;
    if (name === "min_month") {
      dados[id].min_month = val;
    } else if (name === "max_month") {
      dados[id].max_month = val;
    } else if (name === "price") {
      dados[id].price = val;
    }
    this.setState({
      data: {
        ...this.state.data,
        delivery_exemption: dados,
      },
    });
  };
  getLocation = async () => {
    const { cep } = this.state.data;
    const retorno = await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then(async (response) => {
        const { data } = response;
        const { erro = null } = data;
        if (erro) {
          alert("Cep inválido");
          return false;
        } else {
          const { logradouro = "", uf = "", localidade = "" } = data;
          data.endereco = logradouro;
          data.state = uf;
          data.city = localidade;
          this.setState({
            data: {
              ...this.state.data,
              endereco: logradouro,
              state: uf,
              city: localidade,
            },
          });
        }
      });
  };

  render() {
    const { classes, saving, success, open, showDeleteUnitAlert, data } =
      this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Cidades
        </Typography>
        <form className={classes.form}>
          <InputLabel htmlFor="email">Cep</InputLabel>
          <FormControl margin="normal" required fullWidth>
            <Input
              id="cep"
              name="cep"
              autoFocus
              value={data.cep}
              onChange={this.onChange}
              onBlur={this.getLocation}
            />
          </FormControl>
          <br />
          <InputLabel htmlFor="email">Estado da Localidade</InputLabel>
          <FormControl margin="normal" required fullWidth>
            <Input
              id="state"
              name="state"
              autoFocus
              value={data.state}
              onChange={this.onChange}
            />
          </FormControl>
          <br />
          <InputLabel htmlFor="email">Cidade da Localidade</InputLabel>

          <FormControl margin="normal" required fullWidth>
            <Input
              id="city "
              name="city"
              autoFocus
              value={data.city}
              onChange={this.onChange}
            />
          </FormControl>
          <br />
          <InputLabel htmlFor="email">Preço da taxa de entrega</InputLabel>
          <FormControl margin="normal" required fullWidth>
            <Input
              id="delivery_price"
              name="delivery_price"
              autoFocus
              value={data.delivery_price}
              onChange={this.onChange}
            />
          </FormControl>
          <br />
          <FormControl margin="normal" required fullWidth>
            <InputLabel id="demo-simple-select-label">
              Tipo de Verificação
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.type}
              onChange={this.onChangeCheck}
            >
              <MenuItem value="city">cidade</MenuItem>
              <MenuItem value="km">Km</MenuItem>
            </Select>
          </FormControl>

          <br />
          {data.type == "km" ? (
            <div>
              {" "}
              <InputLabel htmlFor="email">KM</InputLabel>
              <FormControl margin="normal" required fullWidth>
                <Input
                  type="number"
                  id="km"
                  name="km"
                  autoFocus
                  value={data.km}
                  onChange={this.onChangeCheckKm}
                />
              </FormControl>
              <br />
            </div>
          ) : (
            ""
          )}
          {data.type == "KM" ? (
            <div>
              {" "}
              <InputLabel htmlFor="email">KM</InputLabel>
              <FormControl margin="normal" required fullWidth>
                <Input
                  type="number"
                  id="km"
                  name="km"
                  autoFocus
                  value={data.km}
                  onChange={this.onChangeCheck}
                />
              </FormControl>
              <br />
            </div>
          ) : (
            ""
          )}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            open={open}
            onClose={() => this.setState({ open: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  boxShadow: 5,
                  padding: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "baseline",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <BackspaceIcon
                    style={{
                      position: "absolute",
                      top: -14,
                      right: -11,
                      cursor: "pointer",
                    }}
                    onClick={() => this.setState({ open: false })}
                  />
                  <h4
                    style={{
                      color: "#000",
                      fontFamily: "Roboto, Helvetica , Arial, sans-serif",
                    }}
                    id="transition-modal-title"
                  >
                    Regras de inseção de taxa
                  </h4>

                  <InputLabel
                    style={{ marginTop: 20 }}
                    id="demo-simple-select-label"
                  >
                    Mínimo de meses para a isenção
                  </InputLabel>
                  <Input
                    style={{ color: "#000", margin: 5 }}
                    type="number"
                    id="min_month"
                    name="min_month"
                    autoFocus
                    value={data.delivery_exemption.min_month}
                    onChange={this.onChangeArray}
                  />

                  <InputLabel
                    style={{ marginTop: 20 }}
                    id="demo-simple-select-label"
                  >
                    {" "}
                    Maximo de meses para a isenção
                  </InputLabel>
                  <Input
                    style={{ color: "#000", margin: 5 }}
                    type="number"
                    id="max_month"
                    name="max_month"
                    autoFocus
                    value={data.delivery_exemption.max_month}
                    onChange={this.onChangeArray}
                  />

                  <InputLabel
                    style={{ marginTop: 20 }}
                    id="demo-simple-select-label"
                  >
                    {" "}
                    valor do contrato para verificar a isenção
                  </InputLabel>
                  <Input
                    style={{ color: "#000", margin: 5 }}
                    type="number"
                    id="price"
                    name="price"
                    autoFocus
                    value={data.delivery_exemption.price}
                    onChange={this.onChangeArray}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={saving}
                    onClick={this.regraSalvar}
                  >
                    Salvar Regra
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <br />
          {data.showExemptionMessage && (
            <div
              style={{
                maxWidth: 918,
                overflow: "auto",
                maxHeight: 200,
                minHeight: 100,
              }}
            >
              <Table
                className={classes.table}
                style={{ height: "100%", maxWidth: 818 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Mínimo de meses:</TableCell>
                    <TableCell align="center">Máximo de meses:</TableCell>
                    <TableCell align="center">Preço:</TableCell>
                    <TableCell align="center">Remover</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.delivery_exemption.map((d, index) => (
                    <TableRow
                      key={index}
                      hover
                      /*  className={classes.row} */
                    >
                      <TableCell component="th" scope="row">
                        <Input
                          type="number"
                          id={index}
                          name="min_month"
                          autoFocus
                          value={d.min_month}
                          onChange={this.changeRule}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          type="number"
                          id={index}
                          name="max_month"
                          autoFocus
                          value={d.max_month}
                          onChange={this.changeRule}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          type="number"
                          id={index}
                          name="price"
                          autoFocus
                          value={d.price}
                          onChange={this.changeRule}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => this.deleteRegra(index)}
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <div /* style={{marginTop: 222}} */>
            <FormControl
              margin="normal"
              required
              fullWidth
              style={{ width: "50%" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                disabled={saving}
                onClick={this.modalRegra}
              >
                Regra de inseção de taxa
              </Button>
            </FormControl>

            <br />
            <FormControl margin="normal" required fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    style={{ textAlign: "center" }}
                    checked={data.active == true ? true : false}
                    onChange={this.onCheckChange}
                    value={data.active}
                    color="primary"
                  />
                }
                label="Status"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="homeOffice"
                    style={{ textAlign: "center" }}
                    checked={data.homeOffice == true ? true : false}
                    onChange={this.onCheckChange}
                    value={data.homeOffice}
                    color="primary"
                  />
                }
                label="Insenção de home office"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="showExemptionMessage"
                    style={{ textAlign: "center" }}
                    checked={data.showExemptionMessage}
                    onChange={this.onCheckChange}
                    value={data.showExemptionMessage}
                    color="primary"
                  />
                }
                label="Mostrar valor mínimo no checkout"
              />
            </FormControl>
            <br />

            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                disabled={saving}
                onClick={this.onSavePress}
              >
                <SaveIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Salvar
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                onClick={this.onCancelPress}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </form>

        {showDeleteUnitAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir esta filial ${data.title}? Obs.: Esta ação é irreversível`}
            title="Excluir Filial"
            open={showDeleteUnitAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteUnitAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteUnitAlert: false,
                  });

                  this.deleteUnit();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </Paper>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "475px",
    marginTop: 25,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

export default withStyles(styles)(EditLocation);
