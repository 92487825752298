import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { formatter } from "../../../functions";
import { Colors } from "../../../styles/Colors";
import { ContentContainer } from "../../layout/components/contentContainer";
import ContentHeader from "../../layout/components/contentHeader";
import { Rows } from "../../layout/components/rows";
import { Text } from "../../layout/components/text";
import AlertDialog from "../../utils/AlertDialog";
import Loading from "../../utils/Loading";
import { useShowExerciseOfRights } from "./show-hook";

const contractStatus = {
  active: { status: "Ativo", color: Colors.lightGreen },
  pending: { status: "Pendente", color: Colors.yellow },
  canceled: { status: "Cancelado", color: Colors.red },
  declined: { status: "Reprovado", color: Colors.red },
  finished: { status: "Finalizado", color: "#bbb" },
};

const ContractStatusLabel = ({ status }) => {
  const { status: statusName, color } = contractStatus[status];
  return (
    <Box
      style={{
        backgroundColor: "#eee",
        padding: `2px 8px 2px 8px`,
        borderRadius: 4,
        borderLeft: `8px solid ${color}`,
      }}
    >
      <Text
        strong
        color="#555"
        style={{ textTransform: "uppercase", fontSize: 12 }}
      >
        {statusName}
      </Text>
    </Box>
  );
};

const UserNotFound = () => (
  <Box
    style={{
      display: "inline",
      backgroundColor: Colors.yellow,
      borderRadius: 8,
      padding: `0px 8px`,
      color: "#6e6539",
    }}
  >
    <span>Usuário não encontrado</span>
  </Box>
);

const UserHasActiveContracts = () => (
  <Box
    style={{
      display: "flex",
      backgroundColor: Colors.red,
      borderRadius: 8,
      padding: `0px 8px`,
      color: "#f6e2df",
    }}
  >
    <span>Contratos ativos</span>
  </Box>
);

export const ShowExerciseOfRight = (props) => {
  const id = props.location.pathname.split("/").at(-1);
  const {
    data: [itemData],
    dataLength,
    isLoading,
    anonymizeModalOpen,
    openAnonymizeModal,
    closeAnonymizeModal,
    anonymize,
  } = useShowExerciseOfRights(id);

  const totalContracts = itemData?.contracts?.length || 0;

  if (isLoading) return <p>Carregando...</p>;
  if (typeof itemData !== "object") return <p>Houve um problema</p>;

  return (
    <>
      <Loading loading={isLoading} />
      <ContentHeader
        title={itemData?.name || "Exercício de Direito"}
        link={itemData?.idUser ? `/app/user/${itemData?.idUser}` : null}
        subtitle={
          <Box style={{ display: "flex", gap: 4 }}>
            {itemData?.hasActiveContracts && <UserHasActiveContracts />}
            {!itemData?.idUser && <UserNotFound />}
          </Box>
        }
        buttons={[
          ...(itemData?.idUser
            ? [
                {
                  text: "Anonimizar",
                  disabled: itemData?.hasActiveContracts,
                  destructive: true,
                  onClick: openAnonymizeModal,
                },
              ]
            : []),
        ]}
      />
      {dataLength === 0 ? (
        <p>Solicitação não encontrada</p>
      ) : (
        <>
          <Box style={{ display: "flex", gap: 24 }}>
            <ContentContainer label={`Dados do solicitante`} fullWidth>
              <Rows
                data={[
                  { label: "E-mail", description: itemData.email },
                  { label: "CPF", description: itemData.cpf },
                  {
                    label: "Data de nascimento",
                    description: itemData.dateOfBirth,
                  },
                  {
                    label: "Direito solicitado",
                    description: itemData.requestedRight,
                  },
                  {
                    label: "Enviado em",
                    description: formatter.date(
                      itemData.createdAt,
                      "short",
                      false
                    ),
                  },
                  {
                    label: "Mensagem",
                    description: itemData.message,
                  },
                ]}
              />
            </ContentContainer>
            <ContentContainer label="Documento">
              <a href={itemData.idImgUrl} target="_blank">
                <img
                  src={itemData.idImgUrl}
                  style={{ width: 180, aspectRatio: 1, borderRadius: 8 }}
                />
              </a>
            </ContentContainer>
          </Box>
          {totalContracts > 0 ? (
            <ContentContainer label={`Contratos (${totalContracts})`}>
              <Table>
                <TableHead>
                  <TableRow>
                    {[
                      "Status",
                      "Pedido",
                      "Valor",
                      "Duração",
                      "Data de criação",
                    ].map((row) => (
                      <TableCell key={row}>{row}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemData.contracts.map((item) => {
                    return (
                      <TableRow
                        key={item._id}
                        hover
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(`/app/contract/${item._id}`, "_blank");
                        }}
                      >
                        <TableCell align="center" style={{ width: 260 }}>
                          <Box style={{ width: 160 }}>
                            <ContractStatusLabel status={item.status} />
                          </Box>
                        </TableCell>
                        <TableCell>{item.id_subscription}</TableCell>
                        <TableCell>{formatter.currency(item.price)}</TableCell>
                        <TableCell>{item.period}</TableCell>
                        <TableCell>{item.createdAt}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ContentContainer>
          ) : (
            <Box style={{ textAlign: "center", marginTop: 20 }}>
              <Text>Este usuário não possui nenhum contrato</Text>
            </Box>
          )}
        </>
      )}
      <AlertDialog
        msg={`Deseja relmente anonimizar este este cliente? Esta ação é irreversível!`}
        title="Atenção!"
        open={anonymizeModalOpen}
        buttons={[
          {
            label: "Cancelar",
            onPress: closeAnonymizeModal,
          },
          {
            label: "Anonimizar dados",
            destructive: true,
            onPress: async () => {
              await anonymize(itemData?.idUser)
                .then((response) => {
                  alert(response.data.message);
                  props.history.push("/app/clients/exercise-of-right");
                })
                .catch((error) => {
                  alert(error.response?.data?.error || "Houve um problema");
                })
                .finally(() => {
                  closeAnonymizeModal();
                });
            },
          },
        ]}
      />
    </>
  );
};
